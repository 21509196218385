// Select all collapse elements within the story events
var storyEventCollapseElements = [].slice.call(document.querySelectorAll('.story-event .collapse'));

// Change the appearance of crossed paths
var addCrossedPathStyle = storyEventCollapseElements.map(function (collapseEl) {
  collapseEl.addEventListener('show.bs.collapse', function () {
    // Select current collapsed story event
    var currentStoryEvent = collapseEl.closest('.story-event');

    // Reset all story paths by removing `.path-crossed` class
    var storyPaths = document.querySelectorAll('.story-path');
    removeClassFromElements(storyPaths, 'path-crossed');

    // Select all previous story paths
    var prevStoryPaths = previousSiblings(currentStoryEvent, '.story-path');

    // Change the appearance of all previous story paths by adding `.path-crossed` class
    addClassToElements(prevStoryPaths, 'path-crossed');
  })
})

// Select the previous sibling elements that match a selector
var previousSiblings = function (elem, selector) {
  var siblings = [];
  var sibling = elem.previousElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) {
      siblings.push(sibling);
    }
    sibling = sibling.previousElementSibling;
  }
  return siblings;
};

// Remove a class from elements
var removeClassFromElements = function (elements, className) {
  elements.forEach(elem => {
    elem.classList.remove(className);
  });
}

// Add a class to elements
var addClassToElements = function (elements, className) {
  elements.forEach(elem => {
    elem.classList.add(className);
  });
}
